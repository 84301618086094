<template>
    <v-card>  
        <v-card-text>
            <v-row>
                <v-col >                
                    <s-toolbar 
                        label="Recepcion"
                        dark
                        :color="'#BAB6B5'"
                        transferir
                        @transferir="transferir()"
                        searchInput
                        @textSearch="textSearch($event)" 
                    />
                        <v-row justify="center">
                            <v-col cols="4">
                                <s-date
                                    label="Fecha Inicio"
                                    v-model="filter.DateBegin"
                                    @input="loadChanges()"
                                />
                            </v-col>
                            <v-col cols="4">
                                <s-date
                                    label="Fecha Inicio"
                                    v-model="filter.DateEnd"
                                    @input="loadChanges()"
                                />
                            </v-col>    
                        </v-row>
                        <v-data-table
                            :items="itemsReceptions"
                            item-key="RcfID"
                            dense
                            :headers="headerReceptions"
                            :items-per-page="15"									 								
                            disable-sort
                                                
                            show-select
                            v-model="itemsReceptionsSelected"
                        > 		
                            <template v-slot:item.descriptions="row"> 
                    
                                {{ row.item.TypeCultiveName + " " + row.item.TypeCropName + " " + row.item.VrtName}} 
                            </template>
                        </v-data-table>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col>                
                    <s-toolbar 
                        label="Recepcion a imprimir"
                        dark
                        :color="'#BAB6B5'"                      
                        
                        print
                        @print="dialogPdf = !dialogPdf"
                    />
            
                    <v-data-table
                        :items="itemsReceptionsPrint"
                        item-key="RcfID"
                        dense
                        :headers="headerReceptionsPrint"
                        :items-per-page="15"									 								
                        disable-sort 
                    > 		
                    <template v-slot:item.AsgPrint="row"> 
                        <v-btn 
                            x-small 
                            color="info" 
                            class="mr-2"
                            @click="downloadGuide(row)"
                        >
                            <v-icon style=" font-size: 16px !important">mdi-file-pdf-box</v-icon>
                        </v-btn>
                        </template>
                        <template v-slot:item.descriptions="row"> 
                            {{ row.item.TypeCultiveName + " " + row.item.TypeCropName + " " + row.item.VrtName}} 
                        </template>
                        <template v-slot:item.delete="row">
                            <v-btn
                                fab
                                x-small
                                color="error"
                                elevation="0"
                                block
                                icon
                                @click="clickDeleteRow(row)"
                            >
                                <v-icon style="font-size: 16px !important"
                                >fas fa-times</v-icon
                                >
                            </v-btn>
                        </template>
                    </v-data-table>                    
                </v-col>        
            </v-row>
        </v-card-text> 
        <v-dialog v-if="dialogPdf" v-model="dialogPdf"  
			persistent>
			<reception-plant :referralGuideSelected="itemsReceptionsPrint" @close="dialogPdf = false"></reception-plant>
		</v-dialog>

        <v-dialog v-if="dialogGuidePdf" v-model="dialogGuidePdf" fullscreen
			persistent>
			<guide-pdf :referralGuideSelected="referralGuideSelected" @close="dialogGuidePdf = false"></guide-pdf>
		</v-dialog>
    </v-card>
</template>
<script>

import _sreceptionPlant from "@/services/Technicalassistance/ReceptionPlantService.js";
import ReceptionPlant from "./ReceptionPlantPdf.vue";
import GuidePdf from "../../Logistics/ReferralGuide/LgsPrintGuidePdf.vue"; 
import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";

export default {
		components: {
            ReceptionPlant,
            GuidePdf
		}, 
		props: {
			 
		}, 
		data() {
			return { 
                dialogGuidePdf: false,
                referralGuideSelected : {},
                dialogPdf: false,
                itemsReceptions: [],
                itemsReceptionsPrint: [],
                itemsReceptionsSelected: [],
                AsgPrint: "",
                headerReceptionsPrint: [
                    { text: "ID", value: "RcfID" },
                    { text: "Print", value: "AsgPrint" },
                    { text: "Guia", value: "RcfNumberReferralGuide" },
                    { text: "Fecha", value: "RcfDateReception" },
                    { text: "Cod. Proveedor", value: "PrdCardCode" },
                    { text: "Nom. Proveedor", value: "PrdCardName" },
                    { text: "Num. Jabas", value: "AmountJabasWeigh" },
                    { text: "Fundo", value: "FagName" },
                    { text: "Descrip.", value: "descriptions" },
                    { text: "Peso Bruto.", value: "WbbGrossWeight" },
                    { text: "Eliminar", value: "delete" },
                ],
                headerReceptions: [
                    { text: "ID", value: "RcfID" },
                    { text: "Guia", value: "RcfNumberReferralGuide" },
                    { text: "Fecha", value: "RcfDateReception" },
                    { text: "Cod. Proveedor", value: "PrdCardCode" },
                    { text: "Nom. Proveedor", value: "PrdCardName" },
                    { text: "Num. Jabas", value: "AmountJabasWeigh" },
                    { text: "Fundo", value: "FagName" },
                    { text: "Descrip.", value: "descriptions" },
                    { text: "Peso Bruto.", value: "WbbGrossWeight" },
                ],
                filter: {},
                config: {
                    service: _sreceptionPlant,
                    headers: [
                        { text: "Documento", value: "PrsDocumentNumber", sortable: false },
                        { text: "Trabajador", value: "WorkerName", sortable: true },
                        { text: "Versión", value: "CrdVersion", sortable: true },
                        { text: "Código", value: "CrdCode", sortable: true },
                    ],
                }
            }
        },
        watch: {
            
        },
        methods: {
            textSearch(text){
                console.log("text", text)
                this.filter.Search = text;
                this.loadChanges();
            },
            clickDeleteRow(row){
                let item = row.item;                
                this.itemsReceptionsPrint = this.itemsReceptionsPrint.filter((x) => x.RcfID != item.RcfID);
            },
            loadChanges(){
                this.loadList();
            },
            loadList(){
                _sreceptionPlant.list(this.filter, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.itemsReceptions = r.data;
                        }
                    });
            },
            transferir(){

                this.itemsReceptionsSelected.forEach(element => {
                    var recep =  this.itemsReceptionsPrint.filter((x) => x.RcfID == element.RcfID); 
                    if (recep.length > 0) { 
                        return;
                    }
                    this.itemsReceptionsPrint.push(element)
                }); 
            },
            deleteSelect()
            {
                console.log("eliminar");
            },
            async downloadGuide(row){
                // console.log("row", row.item);
                const RegSerie = row.item.RcfNumberReferralGuide.split('-')[0];
                const RegNumber = row.item.RcfNumberReferralGuide.split('-')[1];
                // console.log("RegSerie", RegSerie);
                // console.log("RegNumber", RegNumber);
                let referralGuide = [];
                await _sreceptionPlant.listD(RegSerie, RegNumber, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            console.log("r.data", r.data);
                            referralGuide = r.data;
                        }
                })

                if(referralGuide.length > 0)
				{
					this.lengthSelected = referralGuide.length;
					this.referralGuideSelected = referralGuide[0];
					this.referralGuideSelected.TypeGuide = 1;


					console.log( "this.referralGuideSelected", this.referralGuideSelected )
					


					await _sReferral
					.detail(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.referralGuideSelected.referralGuideItems = resp.data;
						}
					});

					await _sReferral
					.relateddocuments(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.referralGuideSelected.itemsRelatedDocuments = resp.data;
						}
					});

					await _sReferral
					.vehicles(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							console.log('vehiculos', resp.data);
							this.referralGuideSelected.LgsReferralGuideVehicles = resp.data;
						}
					});

					await _sReferral
					.drivers(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.referralGuideSelected.LgsReferralGuideDrivers = resp.data;
						}
					});
					
					//armamos la serie
					let objSerie = {
						DedDescription : this.referralGuideSelected.RegSerie,
						DedValue : this.referralGuideSelected.RegSerieID,
					}
					this.referralGuideSelected.objSerie = objSerie;
					this.referralGuideSelected.DtrIDStartPointOriginal = this.referralGuideSelected.DtrIDStartPoint;
				}

                if(this.referralGuideSelected.RegID != undefined)
				{
					if(this.referralGuideSelected.RegState == 7){
						await _sReferral.urlSunat(this.referralGuideSelected, this.$fun.getUserID()).then((r) => {
						
							if (r.status == 200) {
								console.log('ressss', r.data);
								this.referralGuideSelected.urlSunat = r.data; 
								this.dialogGuidePdf = true;	
							}
						})
					}else{
						this.referralGuideSelected.urlSunat = "";
						this.dialogGuidePdf = true;	
					}
				}

                this.dialogGuidePdf = true;
            }
        },
        mounted(){
            this.filter.Search="";
            this.loadList();
        }

    }
</script>
import Service from "../Service";
import Vue from "vue";
const resource = "/ReceptionPlant/";

export default {

    list(parameters, requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },
 
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    listD(RegSerie, RegNumber, requestID) {
        return Service.post(
          resource + "listD",
          {},
          {
            params: { RegSerie: RegSerie, RegNumber: RegNumber, requestID: requestID },
          }
        );
      },
  
};